// Supported plugins configuration
GLOBAL.config = {

  // Replace checkboxes and radio buttons
  inputs: $(),

  // Replace selectors
  selectors: $('select'),

  // Carousels
  carousels: {
    /*'#main': {
      wrap: false,
      vertical: false,
      method: 'scroll',
      scrollableItems: 1,
      autoscrollInterval: 0, // ms
      pagination: true,
      touchable: GLOBAL.isTouchscreen
    }*/
  },

  // Modal windows
  modals: {
    '.feedback': {
      node: '#feedback',
      replaceWindowScrollbar: false
    }
  },

  // Popups
  popups: {
    /*'#cat-item-action-faq': {
      rel: '#popup-faq',
      posTop: {
        value: 'under + 10',
        auto: false
      },
      posLeft: {
        value: 'center - 31',
        auto: false
      },
      manipulation: true,
      effect: false
    }*/
  },

  // Tabs
  tabs: {
    '#products': {
      updateHash: false
    },
    '.inner-tabs': {
      updateHash: false
    }
  },

  // Scrollings
  scrollings: {
    '#up': {
      target: 0
    },
    'a#vk-links': {
      target: '#vk-groups'
    },
    '#more-filters': {
      target: '#filters'
    },
    '.contacts-page ul.dotted li > a': {}
  }

};