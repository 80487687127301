$(function () {

  // Responsive
  GLOBAL.actualResizer = 'throttledresize' in jQuery.event.special ? 'throttledresize' : 'resize';
  function responsiveFixes() {
    var ww = $(window)[0].innerWidth || $(window).width(),
        wh = $(window)[0].innerHeight || $(window).height();

    GLOBAL.windowSize = [ww, wh];

    // Prevent fixes if changed only window height
    if ($(document.body).data('originalWW') && $(document.body).data('originalWW') == ww) {
      return;
    }
    $(document.body).data('originalWW', ww);

    // Responsive menu
    // Required: /js/plugins/jquery.event.move.js, /js/plugins/jquery.event.swipe.js
    $('.mobile-menu-link').off('click.ht');
    $('nav').off('.ht');
    if (ww < 768) {
      $('.mobile-menu-link').on('click.ht', function (e) {
        e.preventDefault();
        $(document.body).toggleClass('nav-opened');
      });
      $('nav').on('swipeleft.ht swiperight.ht', function () {
        $('.mobile-menu-link').trigger('click');
      }).on('movestart.ht', function (e) {
        if ((e.distX > e.distY && e.distX < -e.distY) || (e.distX < e.distY && e.distX > -e.distY)) {
          e.preventDefault();
        }
      });
    }
  }
  responsiveFixes();
  $(window).on(GLOBAL.actualResizer, responsiveFixes);




  // Replace checkboxes and radio buttons
  // Required: /js/src/plugins/jquery.inputs.js
  if (GLOBAL.config.inputs.length) {
    GLOBAL.config.inputs.inputs();
  }


  // Replace selectors
  // Required: /js/src/plugins/jquery.stylish-select.js
  if (GLOBAL.config.selectors.length) {
    GLOBAL.config.selectors.sSelect();
  }


  // Carousels
  // Required: /js/src/plugins/jquery.jcarousel.js
  // Optional: /js/src/plugins/jcarousel-plugins/jquery.jcarousel-swipe.js, /js/src/plugins/jcarousel-plugins/jquery.jcarousel-fade.js
  var carouselInit = function (container, options) {
    var
      $carousel = $('.jcarousel-wrapper', container).jcarousel({
        wrap: options.wrap,
        vertical: options.vertical
      }),
      isFadeSupported = $.isFunction($.jcarousel.fn.fade),
      method = isFadeSupported && options.method == 'fade' ? 'fade' : 'scroll';

    $carousel.jcarouselAutoscroll({
      interval: options.autoscrollInterval,
      target: '+=' + options.scrollableItems,
      autostart: !!options.autoscrollInterval,
      method: method // 'fade' not supported here (https://github.com/jsor/jcarousel/issues/806)
    });

    $('.jcarousel-prev', container).on('jcarouselcontrol:active', function () {
      $(this).removeClass('jcarousel-controls-inactive');
    }).on('jcarouselcontrol:inactive', function () {
      $(this).addClass('jcarousel-controls-inactive');
    }).jcarouselControl({
      target: '-=' + options.scrollableItems,
      method: method
    });
    $('.jcarousel-next', container).on('jcarouselcontrol:active', function () {
      $(this).removeClass('jcarousel-controls-inactive');
    }).on('jcarouselcontrol:inactive', function () {
      $(this).addClass('jcarousel-controls-inactive');
    }).jcarouselControl({
      target: '+=' + options.scrollableItems,
      method: method
    });

    if (options.pagination && $carousel.jcarousel('items').length > options.scrollableItems) {
      $('.jcarousel-pagination', container).on('click', function (e) {
        e.preventDefault();
      }).on('jcarouselpagination:active', 'a', function () {
        $(this).addClass('jcarousel-pagination-active');
      }).on('jcarouselpagination:inactive', 'a', function () {
        $(this).removeClass('jcarousel-pagination-active');
      }).jcarouselPagination({
        'item': function (page, carouselItems) {
          return '<a href="">' + page + '</a>';
        },
        method: method
      });
    }

    if ('jcarouselSwipe' in $carousel && options.touchable) {
      var touchConfig = {};
      if (isFadeSupported) {
        touchConfig = {
          draggable: false,
          method: method
        };
      }
      $carousel.jcarouselSwipe(touchConfig);
    }
  };

  $.each(GLOBAL.config.carousels, function (selector, options) {
    if ($(selector).length > 1) {
      $(selector).each(function () {
        carouselInit(this, options);
      });
    } else {
      carouselInit(selector, options);
    }
  });


  // Modal windows
  // Required: /js/src/plugins/jquery.uniloader.js
  var modalInit = function (activator, options) {
    var opts = {};
    if ($(activator).data('modal-node')) {
      opts.node = $(activator).data('modal-node');
    }
    $.overlayLoader(true, $.extend(true, {}, opts, $(activator).data('modal-options') || options));
  };

  for (var i in GLOBAL.config.modals) {
    $(i).data('modal-options', GLOBAL.config.modals[i]).on('click', function (e) {
      e.preventDefault();
      modalInit(this);
    });
  }


  // Popups
  // Required: /js/src/plugins/jquery.unifloat.js
  var popupHandler = function (activator, options) {
    var options = options || $(activator).data('popup-options');
    var target = options.rel || '#' + $(activator).attr('id') + '-content',
        self = activator;
    if (options.manipulation && !$(target).data('unifloat')) {
      $(document.body).append($(target));
      $(target).data('unifloat', true);
    }
    if ($(target).is(':hidden')) {
      $(target).unifloat('show', $.extend(true, {}, options, {
        rel: self
      })).find('input:first').focus();
    } else {
      $(target).hide();
    }
  };

  for (var i in GLOBAL.config.popups) {
    $(i).data('popup-options', GLOBAL.config.popups[i]).on('click', function (e) {
      e.preventDefault();
      popupHandler(this);
    });
  }

  // Close popups (modal windows supported)
  // By clicking on an empty area of the window
  $(document.body).on('click', function (e) {
    var isPopup = false;
    $('.popup-activator, .popup, #overlay, .modal').each(function () {
      if ($(e.target).is(this) || $(e.target).parents().is(this)) {
        isPopup = true;
      }
    });
    if ($(e.target).is('.popup-activator') && $(e.target).parents().is('.popup')) {
      $('.popup').hide();
      popupHandler(e.target);
      return;
    }
    if (isPopup) {
      return;
    } else {
      $('.popup').hide();
    }
  });
  // By close button
  $('.popup-close').not('.modal-close').on('click', function (e) {
    e.preventDefault();
    $(document.body).trigger('click');
  });


  // Tabs
  // Required: /js/src/plugins/jquery.easytabs.js
  $.each(GLOBAL.config.tabs, function (selector, options) {
    if ($(selector).length > 1) {
      $(selector).each(function () {
        $(this).easytabs(options);
      });
    } else {
      $(selector).easytabs(options);
    }
  });


  // Scroll links
  // Required: /js/src/plugins/jquery.scrollTo.js
  var scrollingInit = function (activator, options) {
    var options = options || $(activator).data('scrolling-options');
    var target = options.target || $(activator).data('scrolling-target') || '#' + $(activator).attr('href').split('#')[1],
        duration = options.duration || $(activator).data('scrolling-duration') || 200;
    delete options.target;
    delete options.duration;
    $.scrollTo(target, duration, options);
  };

  for (var i in GLOBAL.config.scrollings) {
    $(i).data('scrolling-options', GLOBAL.config.scrollings[i]).on('click', function (e) {
      e.preventDefault();
      scrollingInit(this);
    });
  }


  // Forms errors processing
  var inputs = '.input, select, textarea, .SSContainerDivWrapper',
      defaultValues = ['', 0];

  var $formErrors = $('.form .form-error');
  if ($formErrors.length) {
    setInterval(function () {
      $formErrors.find(inputs).each(function () {
        var $par = $(this).hasClass('SSContainerDivWrapper') ? $(this).parent().parent() : $(this).parent(),
            val = $(this).hasClass('SSContainerDivWrapper') ? $(this).parent().prev().val() : $(this).val(),
            activeState = $(this).hasClass('SSContainerDivWrapper') ? $(this).is(':visible') : $(this).is(':focus');
        if (activeState) {
          $par.data('wasError', true);
          $par.removeClass('form-error');
        } else {
          if ($par.data('wasError')) {
            $par.removeClass('form-error');
            if ($.inArray(val, defaultValues) != -1) {
              $par.addClass('form-error');
            }
          }
        }
      });
    }, 111);
  }




  // Custom scripts

  // Main rotator
  var mainRotator = '#rotator',
      mainRotatorConfig = {};
  if ($('.swiper-slide', mainRotator).length > 1) {
    mainRotatorConfig = {
      loop: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      parallax: true,
      navigation: {
        nextEl: $('.swiper-button-next', mainRotator),
        prevEl: $('.swiper-button-prev', mainRotator)
      },
      pagination: {
        el: $('.swiper-pagination', mainRotator),
        clickable: true
      }
    };
  }
  var rotator = new Swiper($('.swiper-container', mainRotator), mainRotatorConfig);

  // Products rotators
  $('#products .swiper-container').each(function () {
    var $container = $(this).parent();
    var rotator = new Swiper(this, {
      observer: true,
      observeParents: true,
      spaceBetween: 30,
      slidesPerView: 4,
      slidesPerGroup: 4,
      navigation: {
        nextEl: $('.swiper-button-next', $container),
        prevEl: $('.swiper-button-prev', $container)
      }
    });
  });

  // Brands rotator
  var brands = '#brands',
      rotator = new Swiper($('.swiper-container', brands), {
        loop: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        spaceBetween: 30,
        slidesPerView: 4,
        slidesPerGroup: 4,
        navigation: {
          nextEl: $('.swiper-button-next', brands),
          prevEl: $('.swiper-button-prev', brands)
        }
      });

  // Cat item gallery
  var ciGalleryThumbsId = '#cat-item-gallery-thumbs';
  if ($(ciGalleryThumbsId).length) {
    var ciGalleryTop = new Swiper('#cat-item-gallery .swiper-container', {
      spaceBetween: 10,
      allowTouchMove: false,
      autoHeight: true,
      effect: 'flip',
      flipEffect: {
        slideShadows: false
      }
    });
    var $ciGalleryThumbsLinks = $('a', ciGalleryThumbsId),
        ciGalleryThumbs = new Swiper($('.swiper-container', ciGalleryThumbsId), {
          spaceBetween: 10,
          slidesPerView: 'auto',
          touchRatio: 0.2,
          navigation: {
            nextEl: $('.swiper-button-next', ciGalleryThumbsId),
            prevEl: $('.swiper-button-prev', ciGalleryThumbsId)
          }
        });
    ciGalleryTop.controller.control = ciGalleryThumbs;
    $ciGalleryThumbsLinks.on('click', function (e) {
      e.preventDefault();
      $ciGalleryThumbsLinks.parent().removeClass('active');
      $(this).parent().addClass('active');
      ciGalleryTop.slideTo($ciGalleryThumbsLinks.index(this));
    });
    $ciGalleryThumbsLinks.first().trigger('click');
  }

  // Similar products rotator
  var similar = '#similar',
      similarProductsRotator = new Swiper($('.swiper-container', similar), {
        spaceBetween: 30,
        slidesPerView: 3,
        slidesPerGroup: 3,
        navigation: {
          nextEl: $('.swiper-button-next', similar),
          prevEl: $('.swiper-button-prev', similar)
        }
      });

  // Inner info rotators
  $('.info-rotator .swiper-container').each(function () {
    var $container = $(this).parent();
    var rotator = new Swiper(this, {
      spaceBetween: 20,
      slidesPerView: 5,
      slidesPerGroup: 5,
      navigation: {
        nextEl: $('.swiper-button-next', $container),
        prevEl: $('.swiper-button-prev', $container)
      }
    });
  });

  // Left menu
  var $uls = $('.left-menu > li > a:not(.current) + ul');
  $('.left-menu > li > a').on('mouseover', function () {
    var $ul = $(this).next();
    if ($ul.is(':hidden')) {
      $uls.slideUp('fast').prev().removeClass('opened');
      $(this).addClass('opened');
      $ul.slideDown('normal');
    }
  });

  // Delivery time
  $('.cat-item-status > a').unifloat({
    posTop: {
      value: 'above - 10',
      auto: false
    },
    posLeft: {
      value: 'center - (%%TARGETWIDTH%% / 2)',
      auto: false
    },
    effect: false,
    onShow: function (s, t) {
      $(t).addClass('shown');
    },
    onHide: function (s, t) {
      $(t).removeClass('shown');
    }
  }).on('click', function (e) {
    e.preventDefault();
  });

  // Up
  var $up = $('#up'),
      contentTop = $('#content').offset().top;
  $(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();

    if (scrollTop >= contentTop) {
      $up.addClass('is-visible');
    } else {
      $up.removeClass('is-visible');
    }
    if ((scrollTop + $(window).height()) >= $('.footer-bottom').offset().top) {
      $up.addClass('absolute').css('bottom', $('.footer-bottom').height());
    } else {
      $up.removeClass('absolute').removeAttr('style');
    }
  });

  // Format price
  function formatPrice(price) {
    price = price.toFixed(0);
    return String(price).replace(/(\d*)(\d{3})/,'$1 $2').replace(/(\d*)(\d{3})/,'$1 $2');
  }

  // To basket
  $('.to-basket').on('click', function (e) {
    var self = this,
        href = $(self).attr('href');
    if (!$(this).hasClass('to-basket-added')) {
      e.preventDefault();

      $.ajax(href + '&cherry-accept=json', {
        dataType: 'json',
        complete: function (result) {
          if (result.readyState && result.readyState == 4) {
            try {
              result = $.parseJSON(result.responseText);
            } catch (e) {
              result = null;
              alert('Ошибка сервера! Свяжитесь с администратором сайта');
            }
            if (result) {
              var $basket = $('.basket');
              if (result.count) {
                var id = 'basket-tooltip-' + Math.round(Math.random() * 10000) + 1;

                $basket.addClass('basket-full');
                $(self).addClass('to-basket-added').html('Добавлен <span>Оформить</span>').attr('href', '/cart/');

                $tooltip = $('<div class="tooltip" id="' + id + '">Товар добавлен в корзину</div>').appendTo('body');
                $tooltip.unifloat('show', {
                  rel: self,
                  posTop: {
                    value: 'above - 10',
                    auto: false
                  },
                  posLeft: {
                    value: 'center - (%%TARGETWIDTH%% / 2)',
                    auto: false
                  },
                  onShow: function (s, t) {
                    $(t).addClass('shown');
                  }
                }).oneTime('3s', function () {
                  $('#' + id).removeClass('shown').oneTime(500, function () {
                    $('#' + id).remove();
                  });
                });
              } else {
                $basket.removeClass('basket-full');
              }
              $('.basket-title', $basket).html(result.count);
              $('.basket-summary', $basket).html(result.formatted);
            }
          }
        }
      });
    }
  });

  // Update order total price
  var basketTable = $('.table-basket');
  if (basketTable.length) {
    var updateBasket = function () {
      var total = 0;
      basketTable.find('tr.table-basket-product').each(function () {
        var row = $(this)
          , price = parseFloat(row.find('.table-basket-price').html().replace(/\s+/g, ''))
          , quantity = parseInt(row.find('.spinner-input').val(), 10)
          , sumCell = row.find('.table-basket-summary')
          , sum = price * quantity
          ;
        total += sum;
        sumCell.html(formatPrice(sum));
      });
      $('.order-total b').html(formatPrice(total) + ' руб.');
    };
  }

  // Spinners
  $('input.spinner-input.only-' + (GLOBAL.isTouchscreen ? 'desktop' : 'touch')).remove();

  $('.spinner').append('<a href="" class="to-start" /><a href="" class="to-end" />');
  $('.spinner > a').on('click', function (e) {
    e.preventDefault();

    var $input = $(this).parent().find('input'),
        val = parseFloat($input.attr('value'));
        newVal = $(this).hasClass('to-start') ? (val - 1 <= 1 ? 1 : val - 1) : val + 1;

    $input.attr('value', newVal).trigger('change');
    updateBasket();
  });

  $('input.spinner-input').on('keydown keyup keypress', function () {
    var val = parseFloat($(this).attr('value')),
        newVal = 1;

    if (!isNaN(val) && val > 0) {
      newVal = val;
    }

    updateBasket();
  }).on('blur', function () {
    var val = parseFloat($(this).attr('value')),
        newVal = 1;

    if (isNaN(val) || val <= 0) {
      $(this).attr('value', newVal).trigger('change');
    } else {
      newVal = val;
    }

    updateBasket();
  });

  // Delete products from basket
  $('.table-basket-delete').on('click', function (e) {
    if (!confirm('Вы действительно хотите удалить этот товар из корзины?')) {
      e.preventDefault();
    }
  });

  // Hidden maps
  $('.contacts-map-link').on('click', function (e) {
    e.preventDefault();
    $(this).parents('.contacts-item').find('.contacts-map').toggleClass('hidden');
  });

  // Colorbox
  $('.colorbox, #cat-item-gallery a').colorbox({
    scrolling: false,
    maxWidth: '90%',
    maxHeight: '90%',
    opacity: .75
  });
});